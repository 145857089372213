export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '发票代码', code: 'invoiceCode', type: 'input', width: '120' },
  { label: '税务发票号', code: 'invoiceNo', type: 'input', width: '200' },
  { label: '未税开票金额', code: 'invoiceAmount', type: 'amount', align: 'right', width: '110' },
  { label: '含税开票金额', code: 'totalAmount', type: 'amount', align: 'right', width: '110' },
  { label: '开票税额', code: 'taxAmount', type: 'amount', align: 'right', width: '' },
  { label: '备注', code: 'remark', type: 'input', width: '130' },
  {
    label: '发票状态',
    code: 'invoiceStatus',
    type: 'function',
    width: '',
    handle (index, data) {
      return data.invoiceStatus === '0' ? '正常' : data.invoiceStatus === '1' ? '失控'
        : data.invoiceStatus === '2' ? '作废' : data.invoiceStatus === '3' ? '红冲'
          : data.invoiceStatus === '4' ? '异常' : '正常'
    }
  },
  {
    label: '签收状态',
    code: 'isEleSignFor',
    type: 'function',
    width: '',
    handle (index, data) {
      return data.isEleSignFor === 0 ? '已签收' : '未签收'
      // : data.isEleSignFor === 1 ? '已签收'
      //   : data.qsStatus === 2
    }
  },
  { label: '币种', code: 'currency', type: 'input', width: '' },
  { label: '开票日期', code: 'invoiceDate', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '编辑',
        operationButton: 'edit',
        stateField: 'id',
        stateSymbol: '=',
        stateValue: undefined
      }
    ]
  }
]
export var tableData = []
